.chat {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  background-color: #fff;
  &-header {
    background-color: $color-theme-main;
    padding: 15px 30px;
    padding-top: 15px;
    
    span {
      display: block;
      text-align: center;
      color: #fff;
    }
    
    .chat-close img{
      width: 40px;
      float: right;
      margin-right: -25px;
      position: relative;
      top: -11px;
      left: 7px;
    }
    
    .chat-go-back img{
      width: 40px;
      float: left;
      margin-left: -25px;
      position: relative;
      top: -60px;
      left: -6px;
      margin-bottom: -15px;
    }
    
    .title {
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 5px;
    }
    .greetings {
      font-size: 15px;
    }
  }
  
  @import 'slots';
  
  transition: all 0.4s ease-in-out;
  &.clicked {
    position: relative;
    left: 100%;
  }

  #div-msg-alert {
    color: #ffffff;
    background-color: red;
    display: none;
  }
}

.pages {
  display: none;
}

.title {
  font-size: 18px;
}

.title-bold {
  font-size: 30px;
}

.subtitle {
  font-size: 16px !important;
}

input {
  font-size: 16px !important;
  height: 38px !important;
}

button {
  font-size: 16px !important;
}

.span-img-icon-general{
  flex: 0 0 60px;
  align-self: center;
  justify-self: center;
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border: 1px solid #3ba236;
  border-radius: 50%;
  position: relative;
  top: 10px;
  margin-bottom: 20px;
}

.img-sucess {
  border: none;
}

.img-icon-general {
  padding-top: 8px;
  width: 35px;
  height: 35x;
}

.content-page {
  background-color: #f3f3f3;
}

.division-page {
  padding-bottom: 100%;
}

.division-page-chat-active {
  padding-bottom: 120%;
}

.div-form-mail {
  margin-top: 15px;
  input {
    padding: 3px;
    font-size: 16px;
    width: 90%;
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 10px;
  }
  textarea {
    padding: 3px;
    font-size: 16px;
    width: 90%;
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-bottom: 10px;
  }
  button {
    width: 90%;
    padding: 10px 8px;
    background-color: #3ba236;
    border: 0;
    color: #fff;
    font-size: 18px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 100%;
  }
}

//custom chat

.Twilio-MainContainer {
  box-shadow: none !important;
  right: 10px !important;
}

.Twilio-EntryPoint{
  box-shadow: none !important;
}

.Twilio-MainHeader {
  display: none !important;
}

.img-icon-clock {
  width: 56px;
  height: 33px;
}