&-slots {
  z-index: 1;
  min-height: 0;
  flex: 1 1;
  display: flex;
  flex-flow: column nowrap;
  background-color: $color-theme-main;

  .slot {
    background-color: #fff;
    flex: 1 1 50%;
    padding: 0 10px;
    // max-height: 150px;
    min-height: 0;
    border: 1px solid rgba(gray, $alpha: 0.1);
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    align-items: center;
    &:hover {
      position: relative;
      // border-left: 3px solid $color-theme-main;
      transform: translateX(3px);
      transition: all 0.2s ease-in-out;
      z-index: 2;
      background-color: rgb(243, 243, 243);
      //transform: scale(1.05);
      // box-shadow: 0 0 4px rgba(0, 0, 0, 1);
    }

    .img {
      flex: 0 0 60px;
      align-self: center;
      justify-self: center;
      display: block;
      width: 60px;
      height: 60px;
      overflow: hidden;
      border: 1px solid $color-theme-main;
      border-radius: 50%;
      position: relative;
      & > * {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        //height: 50px;
        padding: 5px;
        path {
          fill: $color-theme-main;
        }
      }
      .st2 {
        stroke: #ffffff;
      }
    }

    .content {
      display: block;
      margin-left: 15px;
      margin-right: 30px;
      width: 80%;
      .title {
        display: block;
        // font-weight: bold;
        margin-bottom: 8px;
      }

      &__text {
        .body {
          display: block;
          font-size: 12px;
          color: gray;
          line-height: 15px;
        }
      }

      &__action {
        div {
          position: relative;
          display: block;
          width: 100%;
          display: flex;

          input {
            padding: 3px;
            font-size: 10px;
            width: 100%;
            border: 1px solid rgba(gray, $alpha: 0.2);
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          button {
            padding: 3px 8px;
            background-color: $color-theme-main;
            border: 0;
            color: #fff;
            font-size: 18px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
      }

      &__label {
        .label {
          color: $color-theme-main;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }

  .slot .slot-active {
    transition: all 2s;
    position: absolute;
    height: 100%;
  }
}
