@import 'abstracts/mixins';

// 2. Base stuff
@import 'base/fonts', 'base/fonts', 'base/variables';
body {
  height: 200vh;
}
.Twillio-Chat {
  * {
    box-sizing: border-box;
    font-family: $font-roboto;
  }
  // 1. Configuration and helpers

  position: fixed;
  bottom: 10px;
  right: 10px;
  // overflow: hidden;
  .chat-wrapper {
    position: relative;
    // transition: all 0.3s;
    background-color: $color-theme-main;
    box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.75);
    width: $chat-size-collapsed;
    height: $chat-size-collapsed;
    border-radius: 50%;
    overflow: hidden;
    .toggle-btn {
      position: relative;
      color: #fff;
      cursor: pointer;
      // font-size: 40px;
      display: block;
      z-index: 2;
      top: -1px;
      // left: -3px;
      // width: 110%;
      // height: 110%;
      // overflow: hidden;
      // transform: translateX(50%);
      // border-radius: 50%;
      // padding: 2px;

      // background-color: black;
      circle {
        fill: $color-theme-main;
        stroke: $color-theme-main;
      }
      path.st0 {
        fill: $color-theme-main;
        stroke: $color-theme-main;
      }
      .st1 {
        fill: #ffffff;
        // stroke: $color-theme-main;
      }
      .st2 {
        fill: #ffffff;
        stroke: $color-theme-main;
      }
      .st3 {
        fill: $color-theme-main;
        stroke: $color-theme-main;
      }
    }
    .chat {
      transition: all 0.2s ease;
      opacity: 0;
      visibility: hidden;
    }
  }

  &-active .chat-wrapper {
    .toggle-btn {
      // transform: translate(0);
      // opacity: 0;
      display: none;
    }
    width: $chat-size-window-width;
    height: $chat-size-window-height;
    max-width: 350px;
    max-height: 500px;
    border-radius: 3px;
    overflow: hidden;
    // border: 1px solid black;
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.4);

    #clickprofitwidgetid {
        z-index: 1021;
        cursor: pointer;
        bottom: 16px;
        right: 0px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        display: flex;
        overflow-x: hidden;
        position: fixed !important;
        height: 100%;
        width: 100%;
        border: none;
        top: 0px;
    }

    @import 'components/chat';
  }

  @media all and (min-width:321px) and (max-width: 480px) {

    &-active .chat-wrapper {
      .toggle-btn {
        display: none;
      }
      left: 10px!important;
      top: 11px!important;
      width: 100vw!important;
      height: 100vh!important;
      max-width: 100%!important;
      max-height: 100%!important;
      border-radius: 3px!important;
      overflow: hidden!important;
      box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.4) !important;

      @import 'components/chat';
    }

  }

}

.Twilio-PendingEngagementCanvas{
    background-color: #f3f3f3 !important;
}
